var planet = planet || {};

planet.carousel = function () {

  let carouel = $("[data-carousel]")
  carouel.each(function (){
    let _self = $(this);

    _self.slick({
			infinite: true,
			slidesToShow: 4,
      autoplay: true,
			// slidesToScroll: 4,
			// prevArrow: '',
			// nextArrow: '',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						// slidesToScroll: 4,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						// slidesToScroll: 3,
					},
				},
				{
					breakpoint: 557,
					settings: {
						slidesToShow: 2,
						// slidesToScroll: 2,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						// slidesToScroll: 1,
					},
				},
			],
		});
	
  });
};


const menuicon = document.querySelector("#menu-icon") ;

menuicon.addEventListener("click",  () => {
	menuicon.classList.toggle('iconchange')
})


$(document).ready(function() {
  console.log("Ready !!");
  planet.carousel();
});